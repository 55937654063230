import React from "react"
import styled from "styled-components"
import SectionWrapper from "./section-wrapper"
import Button from "./button"
import { Link } from "gatsby"

const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Card = styled.div`
  position: relative;
  margin: 5px;
  background-color: ${props => props.theme.colors.background};
  padding: 0 20px;
  width: 100%;

  @media (min-width: ${props => props.theme.maxWidth}) {
    width: 320px;
    height: 500px;
    border: 4px solid ${props => props.theme.colors.primary}40;
  }

  p span {
    font-weight: bold;
  }
`

const Header = styled.h3`
  font-size: ${props => props.theme.fontSizes.big};
`

const PriceTag = styled.div`
  font-weight: bold;
  text-align: right;

  @media (min-width: ${props => props.theme.maxWidth}) {
    right: 20px;
    position: absolute;
    bottom: 20px;
  }
`

const StyledLink = styled(Link)`
  margin: 30px;
  width: 240px;
  align-self: flex-end;
  text-decoration: none;
`

const OfferSection = () => (
  <Wrapper title="Oferta">
    <CardContainer>
      <Card>
        <Header>Konsultacje psychologiczne</Header>
        <p>
          Przeznaczone dla osób, które potrzebują rozeznać się w swoich
          potrzebach psychologicznych, uzyskać wsparcie informacyjne i
          emocjonalne.
        </p>
        <PriceTag>160zł / 50min</PriceTag>
      </Card>
      <Card>
        <Header>Terapia krótkoterminowa</Header>
        <p>
          Obejmuje szereg spotkań, które mają na celu przepracowanie pewnych
          problemów, które pojawiły się na drodze życia. Czasami jej efektem
          jest bezpieczne i konstruktywne przejście przez stresujące wydarzenie
          takie jakim jest wejście w nową rolę lub doświadczenie kryzysu
          (rozstanie z partnerem, utrata lub zmiana miejsca pracy, strata
          bliskiej osoby lub członka rodziny). Dla kogoś innego celem terapii
          krótkoterminowej może być zmiana własnego zachowania lub postrzegania
          pewnych zdarzeń.
        </p>
        <PriceTag>160zł / 50min</PriceTag>
      </Card>
      <Card>
        <Header>Terapia długoterminowa</Header>
        <p>
          Kierowana do osób, które doświadczają uporczywych objawów natury
          psychicznej (obniżenie lub wahania nastroju, lęk, bezsenność, objawy
          psychosomatyczne) oraz osób, które zaobserwowały u siebie powtarzające
          się wzorce zachowania, które mają destrukcyjny charakter (zachowania
          te mogą się pojawiać w różnych strefach: zawodowej, związanej z
          relacjami lub z własną samooceną oraz postrzeganiem siebie).
        </p>
        <p>
          Jej celem jest leczenie zaburzeń osobowości, wsparcie leczenia
          zaburzeń nastroju, lękowych, zaburzeń odżywiania.
        </p>
        <PriceTag>160zł / 50min</PriceTag>
      </Card>
    </CardContainer>
    <StyledLink to={"/contact"}>
      <Button trackingId="Offer - Make an Appointment">Umów wizytę</Button>
    </StyledLink>
  </Wrapper>
)

export default OfferSection
