import React from "react"

import SEO from "../components/seo"
import MainLayout from "../layouts/main-layout"
import OfferSection from "../components/offer-section"

const OfferPage = () => (
  <MainLayout>
    <SEO title="Offer" />
    <OfferSection />
  </MainLayout>
)

export default OfferPage
